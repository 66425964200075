import React from 'react'
import { Grid, useMediaQuery } from '@mui/material'
import theme from '@flock/shared-ui/src/theme/theme'
import { graphql, useStaticQuery } from 'gatsby'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import SectionLayout from '../components/SharedComponents/SectionLayout'
import InlineMessageAddress from '../components/SharedComponents/InlineMessageAddress'
import {
  useRecordPageDuration,
  useScrollDepthTracker,
} from '../components/utils'
import { WebinarModal } from '../components/SharedComponents/WebinarModal'
import HowItWorksContent from '../components/HowFlockComponents/HowItWorksContent/HowItWorksContent'

const pageTitle = 'Flock | How it works'
const pageDescription = `Interested in a Flock exchange? Here's our simple 5-step process.`

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const HowItWorks = () => {
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  useRecordPageDuration()
  useScrollDepthTracker()

  const { getPropertySummaryDetails } = useStaticQuery(graphql`
    query {
      getPropertySummaryDetails {
        asOfDate
      }
    }
  `)

  return (
    <PageWrapper
      title={pageTitle}
      trackingName="how-it-works"
      headerBackground="trustBlue.main"
      asOfDate={getPropertySummaryDetails.asOfDate}
    >
      <SectionLayout name="inline-message" backgroundColor="trustBlue.main">
        <HowItWorksContent />
        <Grid item xs={12}>
          <InlineMessageAddress
            text="Landlords, retire and minimize taxes."
            variant={isTablet ? 'button' : 'address'}
            label="Get Started"
          />
        </Grid>
        <Grid item xs={12} textAlign="end" pt="16px">
          <WebinarModal />
        </Grid>
      </SectionLayout>
    </PageWrapper>
  )
}

export default HowItWorks
